import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingZero'
})
export class LeadingZeroPipe implements PipeTransform {

  transform(value: number): string {
    if (value) {
      return value < 10 ? `0${value}` : `${value}`;
    } else {
      return '00';
    }
  }

}
