import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeadingZeroPipe } from './leading-zero.pipe';

@NgModule({
  declarations: [LeadingZeroPipe],
  imports: [CommonModule],
  exports: [LeadingZeroPipe],
})
export class SharedUiModule {}
