import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedUiModule } from '../../shared-ui/shared-ui.module';
import { TimePipe } from '../../shared-ui/time.pipe';
import { ActionChooserComponent } from './action-chooser/action-chooser.component';
import { ActionTriggerEditorComponent } from './action-trigger-editor/action-trigger-editor.component';

const routes: Routes = [
  /*
    {
        path: '',
        redirectTo: 'editor',
        pathMatch: 'full'
    },
    */
  {
    path: 'editor/add/:position',
    component: ActionTriggerEditorComponent,
  },
  {
    path: 'editor',
    component: ActionTriggerEditorComponent,
  },
];

@NgModule({
  declarations: [ActionTriggerEditorComponent, ActionChooserComponent],
  imports: [CommonModule, IonicModule, SharedUiModule, RouterModule.forChild(routes), TimePipe],
  exports: [ActionChooserComponent],
})
export class ActionTriggerModule {}
